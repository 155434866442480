import { Link, graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Service from './service';
import StyledButton from './styledButton';

const Services = () => {
  const { services } = useStaticQuery(graphql`
    query {
      services: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/services/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              slug
              path
              icon
              price
              blurb
              weight
            }
            excerpt
          }
        }
      }
    }
  `);

  return (
    <div className='flex flex-col'>
      <p className='mb-2 text-4xl text-gray-800 self-center text-center mt-10'>
        Our Services
      </p>
      <div className='flex flex-wrap justify-center items-stretch -mx-2'>
        {services.edges
          .sort((a, b) => a.node.frontmatter.weight - b.node.frontmatter.weight)
          .map(({ node }) => (
            <>
              <Service
                title={node.frontmatter.title}
                slug={node.frontmatter.slug}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
                icon={node.frontmatter.icon}
                price={node.frontmatter.price}
                blurb={node.frontmatter.blurb}
                children={undefined}
              />
            </>
          ))}
      </div>
      <div className='self-center my-3 mx-auto items-center'>
        <Link to='/contact/'>
          <StyledButton className='mx-auto'>Find out more</StyledButton>
        </Link>
      </div>
    </div>
  );
};

export default Services;
